import pino, { Logger, LoggerOptions } from 'pino';

export enum HttpMethod {
    Get = 'GET',
    Post = 'POST'
}

export interface Loader {
    show(isProgress?: boolean): void;
    hide(): void;
    isShow(): boolean;
}

export function initLogger(env: string): Logger<LoggerOptions> {
    let logger = pino();
    logger.level = getLogLevel(env);

    return logger;
}

export function isEmail(emailOrPhoneNumber: string): boolean {
    return emailOrPhoneNumber.indexOf('@') > 0;
}

export function enableFormButton() {
    $('form button[type="submit"]').prop('disabled', false);
}

export function togglePassword() {
    $(document).on('click', 'svg.toggle-password', (e) => {
        let $target = $(e.target);
        let $input = $target
            .closest('div')
            .find('input');

        if ($target.is('path'))
            $target = $target.closest('svg');

        if ($input.is('[type="text"]')) {
            $input.attr('type', 'password');

            if ($target.attr('data-icon') !== 'eye-slash')
                $target.attr('data-icon', 'eye-slash');
        } else {
            $input.attr('type', 'text');
            if ($target.attr('data-icon') !== 'eye')

                $target.attr('data-icon', 'eye');

        }
    });
}

export function enumFromValue<T extends Record<string, string>>(val: string, _enum: T) {
    const enumName = (Object.keys(_enum) as Array<keyof T>).find(k => _enum[k] === val);

    if (!enumName)
        console.error(`Failed to get enum ${_enum} from value ${val}`);

    return _enum[enumName];
}

export function initLoader(): Loader {
    return {
        show: showLoader,
        hide: hideLoader,
        isShow: isLoaderShow
    }
}

function showLoader(isProgress?: boolean) {
    hideLoader();

    if (isProgress === true)
        $('<div class="loader progress"></div>').prependTo('.container');
    else
        $('<div class="loader"></div>').prependTo('.container');
}

function hideLoader() {
    $('.container .loader').remove();
}

function isLoaderShow(): boolean {
    return $('.container .loader').exists();
}

function getLogLevel(env: string): string {
    if (env === 'Development')
        return 'info';

    if (env === 'Sandbox' || env === 'Staging')
        return 'warn';

    if (env === 'Production')
        return 'error';

    return 'silent';
}
