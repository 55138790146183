(($) => {
    $.fn.exists = function () {
        return this.length > 0;
    };

    $.fn.htmlElement = function () {
        return this[0];
    };

    $.fn.htmlElements = function () {
        return $.map(this, m => m) || [];
    };

    $.fn.outerHTML = function () {
        return this[0] ? this[0].outerHTML : '';
    };
})(jQuery);
