import { Modal } from 'bootstrap';
import { FormResult } from '../libs/rekadia/formhelper';
import { PositionClass } from '../libs/rekadia/formhelper.toast';
import { initLogger, initLoader, HttpMethod, Loader } from './utility';
import { Logger, LoggerOptions } from 'pino';

type PopupCallback = (popup: Modal) => void;

export interface Popup {
    showOtp(options: PopupOtpOptions): void;
}

export interface PopupConfigs {
    env: string;
}

export interface PopupOptions {
    title: string;
    body: string;
    btnOK?: string;
    btnCancel?: string;
}

export interface PopupOtpOptions {
    input: PopupOptions;
    result: PopupOptions;
    emailOrPhoneNumber: string;
    resendCode: string;
    redirectBody: string;
    redirectText: string;
    redirectUrl: string;
    regenerateUrl: string;
    verifyUrl: string;
    antiForgeryToken: string;
    enableButtonCancel?: boolean;
    changeEmailOrPhoneNumber?: string;
    enableChangeEmailOrPhoneNumber?: boolean;
    onShow?: PopupCallback | undefined;
}

const $template = $(`<div class="modal popup" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                Header
            </div>
            <div class="modal-body">
                Body
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button">OK</button>
            </div>
        </div>
    </div>
</div>`);

let logger: Logger<LoggerOptions>;
let loader: Loader;

export function initPopup(confgs: PopupConfigs): Popup {
    logger = initLogger(confgs.env);
    loader = initLoader();

    return {
        showOtp: showOtp
    }
};

function showOtp(options: PopupOtpOptions) {
    const maxToken = 4;
    const timeCountdown = 60;
    const formVerifySelector = '#form-verify';
    const formRegenerateSelector = '#form-regenerate';
    const headerSelector = '.modal-header';
    const bodySelector = '.modal-body';
    const footerSelector = '.modal-footer';
    const inputSelector = '.form-control.otp';
    const submitButtonSelector = 'button.btn-primary';
    const redirectButtonSelector = 'button.btn-link.mb-3';
    const errorVerifySelector = '.error-verification';
    const errorVerifyMessageSelector = '.error-verification-message';
    const $changeEmailOrPhoneNumberTemplate = $(`<button class="btn btn-link text-gradient text-decoration-underline fw-bold py-0" type="button" data-bs-dismiss="modal">
    ${options.changeEmailOrPhoneNumber}
</button>`);
    const $errorVerificationTemplate = $(`<div class="error-verification" style="display: none;">
    <i class="fas fa-info-circle fa-lg"></i>
    <div class="error-verification-message fs-6"></div>
</div>`);
    const $formTokenTemplate = $(`<form id="form-verify" action="${options.verifyUrl}" class="form-popup" method="post" datatype="FormData" novalidate="novalidate">
    <input name="EmailOrPhoneNumber" type="hidden" value="${options.emailOrPhoneNumber}" />
    <input name="ReturnUrl" type="hidden" value="${options.redirectUrl}" />
</form>`);
    const $formResendTemplate = $(`<form id="form-regenerate" action="${options.regenerateUrl}" class="form-popup" method="post" datatype="FormData" novalidate="novalidate">
    <input name="EmailOrPhoneNumber" type="hidden" value="${options.emailOrPhoneNumber}" />
    <div class="text-countdown regenerate" data-retry="0">
        ${timeCountdown}s
    </div>
    <button class="btn btn-link text-gradient text-decoration-underline fw-bold px-1 py-0" type="submit" disabled>
        ${options.resendCode}
    </button>
</form>`);
    const $bodyRedirectTemplate = $(`<div class="body-redirect">
    ${options.redirectBody}
    <span class="text-countdown redirect">
        ${timeCountdown}s
    </span>
</div>`);
    const $buttonCancelTemplate = $(`<button class="btn btn-link fs-5 fw-bold" type="button" data-bs-dismiss="modal">
    ${options.input.btnCancel || 'Cancel'}
</button>`);
    const buttonRedirectTemplate = `<button class="btn btn-link text-gradient text-decoration-underline fw-bold mb-3 py-0" type="button">
    ${options.redirectText}
</button>`;
    const $antiForgeryTokenTemplate = $(`<input name="__RequestVerificationToken" type="hidden" value="${options.antiForgeryToken}" />`);
    let regenerateTimer: number;
    let redirectTimer: number;
    let popup: Modal;
    let element: HTMLElement;
    let $popup = $template.clone();
    let $errorVerification = $errorVerificationTemplate.clone();
    let $formToken = $formTokenTemplate.clone();
    let $formResend = $formResendTemplate.clone();
    let redirectUrl = options.redirectUrl;

    $popup.attr('id', 'popup-otp');

    // setup header
    $popup.find(headerSelector).html(options.input.title);

    // setup body
    $popup.find(bodySelector).html(`<div class="body-input">${options.input.body}</div>`);

    if (options.enableChangeEmailOrPhoneNumber === true) {
        let $template = $changeEmailOrPhoneNumberTemplate.clone();
        $template.appendTo($popup.find(bodySelector));
    }

    $errorVerification.appendTo($popup.find(bodySelector));

    for (var i = 1; i <= maxToken; i++) {
        let autofocus = '';
        let next = '';
        let previous = '';

        if (i === 1)
            autofocus = 'autofocus ';

        if (i !== maxToken)
            next = `data-next="Token${i + 1}"`;

        if (i !== 1)
            previous = `data-previous="Token${i - 1}"`;

        $(`<input id=Token${i} name=Token${i} class="form-control otp" type="number" placeholder="0" ${next} ${previous} ${autofocus} aria-invalid="false"/>`).appendTo($formToken);
    }

    $antiForgeryTokenTemplate.clone().appendTo($formToken)
    $formToken.appendTo($popup.find(bodySelector));

    $antiForgeryTokenTemplate.clone().appendTo($formResend)
    $formResend.appendTo($popup.find(bodySelector));

    // setup footer
    $popup.find(`${footerSelector} .btn-primary`).html(options.input.btnOK || 'Send');

    if (options.enableButtonCancel === true)
        $buttonCancelTemplate.clone().appendTo($popup.find(footerSelector));
    else
        $popup.find(`${footerSelector} .btn-primary`).addClass('mb-4');

    $('body').append($popup);
    element = document.getElementById($popup.attr('id'));

    popup = new Modal(element, {
        backdrop: 'static',
        keyboard: false
    });

    if (typeof (options.onShow) === 'function')
        options.onShow(popup);

    element.addEventListener('show.bs.modal', (e) => {
        runRegenerateCountdown();

        $(formRegenerateSelector).useFormHelper({
            url: options.regenerateUrl,
            method: HttpMethod.Post,
            enableButtonAfterSuccess: false,
            resetFormAfterSuccess: false,
            toastPositionClass: PositionClass.TopRight,
            showToast: false,
            showValidationErrors: false,
            callback: (response: FormResult, _textStatus: JQuery.Ajax.SuccessTextStatus, _jqXHR: JQuery.jqXHR) => {
                if (response.isSucceed) {
                    runRegenerateCountdown();
                    logger.warn('The OTP is:', response.object);

                    return;
                }

                $(`${errorVerifySelector} ${errorVerifyMessageSelector}`).html(response.message);
                $(errorVerifySelector).show();
            }
        });

        $(formVerifySelector).useFormHelper({
            url: options.verifyUrl,
            method: HttpMethod.Post,
            redirectDelay: 5000,
            enableRedirectDelay: true,
            enableButtonAfterSuccess: true,
            resetFormAfterSuccess: false,
            toastPositionClass: PositionClass.TopRight,
            showToast: false,
            showValidationErrors: false,
            beforeSubmit: (_jqXHR: JQuery.jqXHR, _settings: JQuery.Ajax.AjaxSettingsBase<any>, _form: JQuery<HTMLElement>) => {
                loader.show(true);
                $(`${footerSelector} ${submitButtonSelector}`).prop('disabled', true);
            },
            callback: (response: FormResult, _textStatus: JQuery.Ajax.SuccessTextStatus, _jqXHR: JQuery.jqXHR) => {
                $(`${footerSelector} ${submitButtonSelector}`).prop('disabled', false);
                loader.hide();

                if (!response.isSucceed) {
                    if (response.validationErrors && response.validationErrors.length > 0) {
                        response.validationErrors.forEach((each) => {
                            $(`${bodySelector} input[name="${each.propertyName}"]`).addClass('error');
                        });

                        return;
                    }

                    $(`${errorVerifySelector} ${errorVerifyMessageSelector}`).html(response.message);
                    $(errorVerifySelector).show();
                    $(inputSelector).addClass('error');

                    return;
                }

                redirectUrl = response.redirectUri;

                $popup.find(headerSelector).html(options.result.title);

                $popup.find(bodySelector).html(`<div class="body-result">${options.result.body}</div>`);
                $bodyRedirectTemplate.clone().appendTo($popup.find(bodySelector));
                runRedirectCountdown();
                $popup.find(footerSelector).html(buttonRedirectTemplate);
            }
        });

        $(bodySelector).on('keypress', inputSelector, function (e) {
            if ($(this).val().toString().length === 1)
                return false;
        });

        $(bodySelector).on('keyup', inputSelector, function (e) {
            let $parent = $(this).parent();

            // backspace(8) or left arrow(37)
            if (e.keyCode === 8 || e.keyCode === 37) {
                let $prev = $parent.find('input#' + $(this).data('previous'));

                if ($prev.exists())
                    $prev.select();

                removeErrorInput(this);
                removeErrorValidation();
            }

            // number(48-57) or numpad(96-105) or right arrow(39)
            if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                let $next = $parent.find('input#' + $(this).data('next'));

                if ($next.exists())
                    $next.select();

                removeErrorInput(this);
                removeErrorValidation();
            }
        });

        $(bodySelector).on('blur', inputSelector, function (e) {
            removeErrorInput(this);
            removeErrorValidation();
        });

        $(footerSelector).on('click', submitButtonSelector, function (e) {
            $(formVerifySelector).submit();
        });

        $(footerSelector).on('click', redirectButtonSelector, function (e) {
            window.location.replace(redirectUrl);
        });
    });

    element.addEventListener('hide.bs.modal', (e) => {
        setTimeout(() => {
            $(element).remove();

            if (regenerateTimer)
                clearInterval(regenerateTimer);

            if (redirectTimer)
                clearInterval(redirectTimer);
        }, 100);
    });

    popup.show();

    function removeErrorInput(element: HTMLElement) {
        if ($(element).val() !== '')
            $(element).removeClass('error');
    }

    function removeErrorValidation() {
        if ($(errorVerifySelector).is(':visible')) {
            $(errorVerifySelector).hide();
            $(`${errorVerifySelector} ${errorVerifyMessageSelector}`).html('');
            $(inputSelector).removeClass('error');
        }
    }

    function runRegenerateCountdown() {
        let retry = 0;
        let timeleft = timeCountdown;
        let textCountdownSelector = '.text-countdown.regenerate';
        let btnSubmitSelector = `${formRegenerateSelector} button[type="submit"]`;

        retry = $(textCountdownSelector).data('retry');

        if (retry <= 0)
            retry = 1;

        timeleft = timeleft * retry;

        regenerateTimer = window.setInterval(() => {
            if (timeleft <= 0) {
                clearInterval(regenerateTimer);
                $(textCountdownSelector).hide();
                $(textCountdownSelector).attr('data-retry', $(textCountdownSelector).data('retry') + 1);
                $(textCountdownSelector).html('');
                $(btnSubmitSelector).prop('disabled', false);
                regenerateTimer = undefined;
            } else {
                $(textCountdownSelector).show();
                $(textCountdownSelector).html(`${timeleft}s`);
                $(btnSubmitSelector).prop('disabled', true);
            }

            timeleft -= 1;
        }, 1000);
    }

    function runRedirectCountdown() {
        let timeleft = timeCountdown;
        let textCountdownSelector = '.text-countdown.redirect';

        redirectTimer = window.setInterval(() => {
            if (timeleft <= 0) {
                clearInterval(redirectTimer);
                $(textCountdownSelector).hide();
                $(textCountdownSelector).html('');
                redirectTimer = undefined;
            } else {
                $(textCountdownSelector).html(`${timeleft}s`);
            }

            timeleft -= 1;
        }, 1000);
    }
}
