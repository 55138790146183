import 'jquery';
import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import '@fortawesome/fontawesome-free/js/all';
import '@rekadia/formhelper';
import '@ripple';
import '@jquery-extension';
import '@jquery-validation-extension';
import '@lib-extension';
import forgotpassword from './pages/forgotpassword';
import resetpassword from './pages/resetpassword';
import signin from './pages/signin';
import signup from './pages/signup';
import verify from './pages/verify';
import signinRedirect from './redirect/signin';
import signoutRedirect from './redirect/signout';

export default {
    pages: {
        forgotpassword: forgotpassword,
        resetpassword: resetpassword,
        signin: signin,
        signup: signup,
        verify: verify
    },
    redirect: {
        signin: signinRedirect,
        signout: signoutRedirect
    },
    version: '1.0.0'
};
