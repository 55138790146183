export enum ToastType {
    Error = 'error',
    Info = 'info',
    Success = 'success',
    Warning = 'warning'
}

export enum PositionClass {
    BottomRight = 'formhelper-toast-bottom-right',
    BottomLeft = 'formhelper-toast-bottom-left',
    BottomCenter = 'formhelper-toast-bottom-center',
    BottomFullWidth = 'formhelper-toast-bottom-full-width',
    TopRight = 'formhelper-toast-top-right',
    TopLeft = 'formhelper-toast-top-left',
    TopCenter = 'formhelper-toast-top-center',
    TopFullWidth = 'formhelper-toast-top-full-width'
}

export enum IconClass {
    Error = 'formhelper-toast-error',
    Info = 'formhelper-toast-info',
    Success = 'formhelper-toast-success',
    Warning = 'formhelper-toast-warning'
}

export enum ShowMethod {
    FadeIn = 'fadeIn',
    SlideDown = 'slideDown',
    Show = 'show'
}

export enum HideMethod {
    FadeOut = 'fadeOut',
    Hide = 'hide'
}

export enum EasingType {
    Linear = 'linear',
    Swing = 'swing'
}

export interface ToastOptions {
    tapToDismiss?: boolean | undefined;
    toastClass?: string | undefined;
    containerId?: string | undefined;
    debug?: false | undefined;
    showMethod?: ShowMethod | undefined;
    showDuration?: number | undefined;
    showEasing?: EasingType | undefined;
    onShown?: (this: HTMLElement) => void | undefined;
    hideMethod?: HideMethod | undefined;
    hideDuration?: number | undefined;
    hideEasing?: EasingType | undefined;
    onHidden?: () => void | undefined;
    closeMethod?: boolean | undefined;
    closeDuration?: boolean | undefined;
    closeEasing?: boolean | undefined;
    closeOnHover?: boolean | undefined;
    extendedTimeOut?: number | undefined;
    iconClass?: IconClass | undefined;
    positionClass?: PositionClass | undefined;
    timeOut?: number | undefined;
    titleClass?: string | undefined;
    messageClass?: string | undefined;
    escapeHtml?: boolean | undefined;
    target?: string | undefined;
    closeButton?: boolean | undefined;
    closeHtml?: string | undefined;
    closeClass?: string | undefined;
    newestOnTop?: boolean
    preventDuplicates?: boolean | undefined;
    progressBar?: boolean | undefined;
    progressClass?: string | undefined;
    rtl?: boolean | undefined;
    onClick?: (handler?: JQuery.ClickEvent<HTMLElement, null, HTMLElement, HTMLElement> | false) => JQuery<HTMLElement> | undefined;
    onCloseClick?: (handler?: JQuery.ClickEvent<HTMLElement, null, HTMLElement, HTMLElement> | false) => JQuery<HTMLElement> | undefined;
}

type NotifyCallback = (message: string, title: string, optionsOverride: ToastOptions) => JQuery<HTMLElement>;

interface Toast {
    version: string;
    options: ToastOptions;
    getContainer: (options?: ToastOptions, create?: boolean) => JQuery<HTMLElement>;
    success: NotifyCallback;
    info: NotifyCallback;
    warning: NotifyCallback;
    error: NotifyCallback;
    remove: (element: JQuery<HTMLElement>) => void;
    clear: (element: JQuery<HTMLElement>, clearOtions: ClearOptions) => void;
    subscribe: (callback: (args: Response) => void) => void;
}

interface ClearOptions {
    force?: boolean;
}

interface NotifyOptions {
    type: ToastType;
    iconClass: IconClass;
    message: string;
    optionsOverride?: ToastOptions | undefined;
    title: string;
}

interface Response {
    toastId: number;
    state: 'visible' | 'hidden';
    startTime: Date;
    endTime?: Date | undefined;
    options: ToastOptions;
    map: NotifyOptions;
}

interface ProgressBar {
    intervalId?: number;
    hideEta?: number;
    maxHideTime?: number;
};

const defaultOptions: ToastOptions = {
    tapToDismiss: true,
    toastClass: 'formhelper-toast',
    containerId: 'formhelper-toast-container',
    debug: false,

    showMethod: ShowMethod.FadeIn, //fadeIn, slideDown, and show are built into jQuery
    showDuration: 300,
    showEasing: EasingType.Swing, //swing and linear are built into jQuery
    onShown: undefined,
    hideMethod: HideMethod.FadeOut,
    hideDuration: 1000,
    hideEasing: EasingType.Swing,
    onHidden: undefined,
    closeMethod: false,
    closeDuration: false,
    closeEasing: false,
    closeOnHover: true,

    extendedTimeOut: 1000,
    iconClass: IconClass.Info,
    positionClass: PositionClass.TopRight,
    timeOut: 5000, // Set timeOut and extendedTimeOut to 0 to make it sticky
    titleClass: 'formhelper-toast-title',
    messageClass: 'formhelper-toast-message',
    escapeHtml: false,
    target: 'body',
    closeButton: false,
    closeHtml: '<button type="button">&times;</button>',
    closeClass: 'formhelper-toast-close-button',
    newestOnTop: true,
    preventDuplicates: false,
    progressBar: false,
    progressClass: 'formhelper-toast-progress',
    rtl: false,

    onClick: undefined,
    onCloseClick: undefined
};

let previousToast: Toast;
let $container: JQuery<HTMLElement>;
let listener: (args: Response) => void;
let toastId = 0;

let toast: Toast = {
    version: '2.1.4',
    options: {},
    getContainer: getContainer,
    success: success,
    info: info,
    warning: warning,
    error: error,
    remove: remove,
    clear: clear,
    subscribe: subscribe
};

function getContainer(): JQuery<HTMLElement>;
function getContainer(options: ToastOptions): JQuery<HTMLElement>;
function getContainer(options: ToastOptions, create: boolean): JQuery<HTMLElement>;
function getContainer(options?: ToastOptions, create?: boolean): JQuery<HTMLElement> {
    if (!options)
        options = getOptions();

    $container = $(`#${options.containerId}`);

    if ($container.length)
        return $container;

    if (create)
        $container = createContainer(options);

    return $container;
}

function error(message: string, title: string, optionsOverride: ToastOptions): JQuery<HTMLElement> {
    return notify({
        type: ToastType.Error,
        iconClass: IconClass.Error,
        message: message,
        optionsOverride: optionsOverride,
        title: title
    });
}

function info(message: string, title: string, optionsOverride: ToastOptions): JQuery<HTMLElement> {
    return notify({
        type: ToastType.Info,
        iconClass: IconClass.Info,
        message: message,
        optionsOverride: optionsOverride,
        title: title
    });
}

function success(message: string, title: string, optionsOverride: ToastOptions): JQuery<HTMLElement> {
    return notify({
        type: ToastType.Success,
        iconClass: IconClass.Success,
        message: message,
        optionsOverride: optionsOverride,
        title: title
    });
}

function warning(message: string, title: string, optionsOverride: ToastOptions): JQuery<HTMLElement> {
    return notify({
        type: ToastType.Warning,
        iconClass: IconClass.Warning,
        message: message,
        optionsOverride: optionsOverride,
        title: title
    });
}

function clear($toastElement: JQuery<HTMLElement>, clearOptions: ClearOptions) {
    let options = getOptions();

    if (!$container)
        getContainer(options);

    if (!clearToast($toastElement, options, clearOptions))
        clearContainer(options);
}

function remove($toastElement: JQuery<HTMLElement>) {
    let options = getOptions();

    if (!$container)
        getContainer(options);

    if ($toastElement && $(':focus', $toastElement).length === 0) {
        removeToast($toastElement);
        return;
    }

    if ($container.children().length)
        $container.remove();
}

// internal functions
function clearContainer(options) {
    let toastsToClear = $container.children();
    for (let i = toastsToClear.length - 1; i >= 0; i--) {
        clearToast($(toastsToClear[i]), options);
    }
}

function clearToast(toastElement: JQuery<HTMLElement>, options: ToastOptions): boolean;
function clearToast(toastElement: JQuery<HTMLElement>, options: ToastOptions, clearOptions: ClearOptions): boolean;
function clearToast(toastElement: JQuery<HTMLElement>, options: ToastOptions, clearOptions?: ClearOptions): boolean {
    let force = clearOptions && clearOptions.force ? clearOptions.force : false;

    if (toastElement && (force || $(':focus', toastElement).length === 0)) {
        toastElement[options.hideMethod]({
            duration: options.hideDuration,
            easing: options.hideEasing,
            complete: function () { removeToast(toastElement); }
        });

        return true;
    }

    return false;
}

function createContainer(options: ToastOptions) {
    $container = $('<div/>')
        .attr('id', options.containerId)
        .addClass(options.positionClass);

    $container.appendTo($(options.target));
    return $container;
}

function publish(args: Response) {
    if (!listener)
        return;

    listener(args);
}

function subscribe(callback: (args: Response) => void) {
    listener = callback;
}

function notify(map: NotifyOptions): JQuery<HTMLElement> {
    let options = getOptions();
    let iconClass = map.iconClass || options.iconClass;

    if (typeof (map.optionsOverride) !== 'undefined') {
        options = $.extend(options, map.optionsOverride);
        iconClass = map.optionsOverride.iconClass || iconClass;
    }

    if (shouldExit(options, map)) { return; }

    toastId++;

    $container = getContainer(options, true);

    let intervalId: number = null;
    let $toastElement = $('<div/>');
    let $titleElement = $('<div/>');
    let $messageElement = $('<div/>');
    let $progressElement = $('<div/>');
    let $closeElement = $(options.closeHtml);
    let progressBar: ProgressBar = {
        intervalId: null,
        hideEta: null,
        maxHideTime: null
    };
    let response: Response = {
        toastId: toastId,
        state: 'visible',
        startTime: new Date(),
        options: options,
        map: map
    };

    personalizeToast();

    displayToast();

    handleEvents();

    publish(response);

    if (options.debug && console)
        console.log(response);

    return $toastElement;

    function escapeHtml(source) {
        if (source === null)
            source = '';

        return source
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }

    function personalizeToast() {
        setIcon();
        setTitle();
        setMessage();
        setCloseButton();
        setProgressBar();
        setRTL();
        setSequence();
        setAria();
    }

    function setAria() {
        let ariaValue = '';
        switch (map.iconClass) {
            case IconClass.Success:
            case IconClass.Info:
                ariaValue = 'polite';
                break;
            default:
                ariaValue = 'assertive';
        }

        $toastElement.attr('aria-live', ariaValue);
    }

    function handleEvents() {
        if (options.closeOnHover) {
            $toastElement.hover(stickAround, delayedHideToast);
        }

        if (!options.onClick && options.tapToDismiss) {
            $toastElement.click(hideToast);
        }

        if (options.closeButton && $closeElement) {
            $closeElement.click(function (event) {
                if (event.stopPropagation) {
                    event.stopPropagation();
                } else if (event.cancelable !== undefined && event.cancelable !== true) {
                    event.cancelable = true;
                }

                if (options.onCloseClick) {
                    options.onCloseClick(event);
                }

                hideToast(true);
            });
        }

        if (options.onClick) {
            $toastElement.click(function (event) {
                options.onClick(event);
                hideToast();
            });
        }
    }

    function displayToast() {
        $toastElement.hide();
        $toastElement.show()
        $toastElement[options.showMethod]({
            duration: options.showDuration,
            easing: options.showEasing,
            complete: options.onShown
        });

        if (options.timeOut > 0) {
            intervalId = window.setTimeout(hideToast, options.timeOut);
            progressBar.maxHideTime = options.timeOut;
            progressBar.hideEta = new Date().getTime() + progressBar.maxHideTime;

            if (options.progressBar) {
                progressBar.intervalId = window.setInterval(updateProgress, 10);
            }
        }
    }

    function setIcon() {
        if (map.iconClass) {
            $toastElement.addClass(options.toastClass).addClass(iconClass);
        }
    }

    function setSequence() {
        if (options.newestOnTop) {
            $container.prepend($toastElement);
        } else {
            $container.append($toastElement);
        }
    }

    function setTitle() {
        if (map.title) {
            let suffix = map.title;

            if (options.escapeHtml)
                suffix = escapeHtml(map.title);

            $titleElement.append(suffix).addClass(options.titleClass);
            $toastElement.append($titleElement);
        }
    }

    function setMessage() {
        if (map.message) {
            let suffix = map.message;

            if (options.escapeHtml)
                suffix = escapeHtml(map.message);

            $messageElement.append(suffix).addClass(options.messageClass);
            $toastElement.append($messageElement);
        }
    }

    function setCloseButton() {
        if (options.closeButton) {
            $closeElement.addClass(options.closeClass).attr('role', 'button');
            $toastElement.prepend($closeElement);
        }
    }

    function setProgressBar() {
        if (options.progressBar) {
            $progressElement.addClass(options.progressClass);
            $toastElement.prepend($progressElement);
        }
    }

    function setRTL() {
        if (options.rtl)
            $toastElement.addClass('rtl');
    }

    function shouldExit(options, map) {
        if (options.preventDuplicates) {
            if (map.message === previousToast)
                return true;
            else
                previousToast = map.message;
        }

        return false;
    }

    function hideToast();
    function hideToast(override: boolean);
    function hideToast(override?: any) {
        let method = override && options.closeMethod !== false ? options.closeMethod : options.hideMethod;
        let duration = override && options.closeDuration !== false ? options.closeDuration : options.hideDuration;
        let easing = override && options.closeEasing !== false ? options.closeEasing : options.hideEasing;

        if ($(':focus', $toastElement).length && !override)
            return;

        clearTimeout(progressBar.intervalId);

        return $toastElement[<string>method]({
            duration: duration,
            easing: easing,
            complete: function () {
                removeToast($toastElement);

                clearTimeout(intervalId);

                if (options.onHidden && response.state !== 'hidden')
                    options.onHidden();

                response.state = 'hidden';
                response.endTime = new Date();
                publish(response);
            }
        });
    }

    function delayedHideToast() {
        if (options.timeOut > 0 || options.extendedTimeOut > 0) {
            intervalId = window.setTimeout(hideToast, options.extendedTimeOut);
            progressBar.maxHideTime = options.extendedTimeOut;
            progressBar.hideEta = new Date().getTime() + progressBar.maxHideTime;
        }
    }

    function stickAround() {
        clearTimeout(intervalId);
        progressBar.hideEta = 0;
        $toastElement.stop(true, true)[options.showMethod]({
            duration: options.showDuration,
            easing: options.showEasing
        });
    }

    function updateProgress() {
        let percentage = ((progressBar.hideEta - (new Date().getTime())) / progressBar.maxHideTime) * 100;
        $progressElement.width(percentage + '%');
    }
}

function getOptions() {
    return $.extend({}, defaultOptions, toast.options);
}

function removeToast($toastElement: JQuery<HTMLElement>) {
    if (!$container)
        $container = getContainer();

    if ($toastElement.is(':visible'))
        return;

    $toastElement.remove();
    $toastElement = null;

    if ($container.children().length === 0) {
        $container.remove();
        previousToast = undefined;
    }
}

export default toast;
