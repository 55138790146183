(($) => {
    $(() => {
        $('<div class="ripple"><span class="ripple-circle"></span></div>').prependTo('button.btn-primary');

        $(document).on('click.ui.ripple', '.ripple', function (e) {
            let $this = $(this);
            let $offset = $this.parent().offset();
            let $circle = $this.find('.ripple-circle');
            let x = e.pageX - $offset.left;
            let y = e.pageY - $offset.top;

            $circle.css({
                top: y + 'px',
                left: x + 'px'
            });

            $this.addClass('is-active');
        });

        $(document).on('animationend webkitAnimationEnd oanimationend MSAnimationEnd', '.ripple', function (e) {
            $(this).removeClass('is-active');
        });
    });
})(jQuery);
