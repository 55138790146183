(($) => {
    $.validator.setDefaults({
        highlight: (element, _errorClass, _validClass) => {
            let $element = $(element);
            let $container = $element.closest('.form-border');

            if ($container.exists() && !$container.hasClass('error'))
                $container.addClass('error');
        },
        unhighlight: (element, _errorClass, _validClass) => {
            let $element = $(element);
            let $container = $element.closest('.form-border');

            if ($container.exists() && $container.hasClass('error'))
                $container.removeClass('error');
        },
        onkeyup: (element, _event) => $(element).valid()
    });
})(jQuery);

(($) => {
    let index: number;

    function setValidationValues(options: any, ruleName: string, value: any) {
        options.rules[ruleName] = value;

        if (options.message)
            options.messages[ruleName] = options.message;
    }

    $.validator.unobtrusive.adapters.add('phonenumber', ['mode'], (options) => {
        setValidationValues(options, 'phonenumber', options.params['mode']);
    });

    $.validator.unobtrusive.adapters.add('emailorphonenumber', ['emailmode', 'phonenumbermode'], (options) => {
        let value = {
            emailmode: options.params['emailmode'],
            phonenumbermode: options.params['phonenumbermode']
        };

        setValidationValues(options, 'emailorphonenumber', value);
    });

    $.validator.unobtrusive.adapters.add('alphanumeric', (options) => {
        setValidationValues(options, 'alphanumeric', true);
    });

    $.validator.unobtrusive.adapters.add('alphanumericwhitespace', (options) => {
        setValidationValues(options, 'alphanumericwhitespace', true);
    });

    $.validator.messages.password = '';
    index = $.validator.unobtrusive.adapters.findIndex((p) => p.name === 'password');

    if (index > -1)
        $.validator.unobtrusive.adapters.splice(index, 1);

    $.validator.unobtrusive.adapters.add('password', (options) => {
        setValidationValues(options, 'password', true);
    });
})(jQuery);

(($) => {
    enum PhoneNumber {
        IndonesiaSimple = 1,
        IndonesiaCode = 2,
        IndonesiaAdvance = 3
    }

    let optional = $.validator.prototype.optional;

    $.validator.addMethod('phonenumber', function (value, element, params) {
        let mode = PhoneNumber[params];
        let valid = optional.call(this, element);

        switch (parseInt(mode)) {
            case PhoneNumber.IndonesiaAdvance:
                return valid || /^(08\d{2}([ -]?)|(\+628\d{2})|\+62 8\d{2}([ -]))(\d{4})((\d{4,5}|([ -]?)\d{3,5}))$/.test(value);
                break;
            case PhoneNumber.IndonesiaCode:
                return valid || /^\+628\d{9,10}$/.test(value);
            default:
                return valid || /^08\d{9,11}$/.test(value);
        }
    });

    $.validator.addMethod('emailorphonenumber', function (value, element, params) {
        let valid = false;
        let emailValidator = $.validator.methods.email;
        let phonenumberValidator = $.validator.methods.phonenumber;

        valid = emailValidator.call(this, value, element);

        if (valid)
            return true;

        valid = phonenumberValidator.call(this, value, element, params.phonenumbermode);

        return valid;
    });

    $.validator.addMethod('alphanumeric', function (value, element) {
        return optional.call(this, element) || /^[a-zA-Z0-9]+$/.test(value);
    });

    $.validator.addMethod('alphanumericwhitespace', function (value, element) {
        return optional.call(this, element) || /^[a-zA-Z0-9 ]+$/.test(value);
    });

    $.validator.addMethod('password', function (value, element) {
        if ($(element).attr('data-val-password') !== undefined)
            return optional.call(this, element) || /^.*(?=.*\d)(?=.*[a-zA-Z]).*$/.test(value);

        return true;
    })
})(jQuery);
