import { enableFormButton, initLoader, togglePassword, Loader } from '../commons/utility';
import { FormResult } from '../libs/rekadia/formhelper';

let loader: Loader;

function init() {
    $(() => {
        loader = initLoader();

        $('#form-reset').useFormHelper();
        enableFormButton();
        togglePassword();

        loader.hide();
    });
}

function formBeforeSubmit(_jqXHR: JQuery.jqXHR, _settings: JQuery.Ajax.AjaxSettingsBase<any>, _form: JQuery<HTMLElement>) {
    loader.show(true);
}

function formCallback(_response: FormResult, _textStatus: JQuery.Ajax.SuccessTextStatus, _jqXHR: JQuery.jqXHR) {
    loader.hide();
}

export default {
    init: init,
    formBeforeSubmit: formBeforeSubmit,
    formCallback: formCallback
};
