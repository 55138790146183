import { initLogger, initLoader, isEmail, Loader } from '../commons/utility';
import { FormResult, FormResultStatus } from '../libs/rekadia/formhelper';
import { initPopup, Popup } from '../commons/popup';
import { Logger, LoggerOptions } from 'pino';

export interface InitOptions {
    env: string;
    popup: PopupOptions;
}

export interface PopupOptions {
    titleInput: string;
    titleResult: string;
    emailBody: string;
    smsBody: string;
    changeEmail: string;
    changePhoneNumber: string;
    resultBody: string;
    redirectBody: string;
    redirectText: string;
    resendCode: string;
    btnOK: string;
    btnCancel: string;
    regenerateUrl: string;
    verifyUrl: string;
}

const antiForgeryTokenSelector = 'input[name="__RequestVerificationToken"]';
const emailOrPhoneNumberSelector = '#EmailOrPhoneNumber';
const returnUrlSelector = '#ReturnUrl';
const formConfirmationSelector = '#form-generate';
let logger: Logger<LoggerOptions>;
let loader: Loader;
let popup: Popup;
let initOptions: InitOptions;

function init(options: InitOptions) {
    $(() => {
        initOptions = options;
        logger = initLogger(initOptions.env);
        loader = initLoader();
        popup = initPopup({
            env: initOptions.env
        });

        $(formConfirmationSelector).useFormHelper();

        loader.hide();
    });
}

function showPopup() {
    let body: string;
    let changeEmailOrPhoneNumber = initOptions.popup.changeEmail;
    let emailOrPhoneNumber = $(emailOrPhoneNumberSelector).val().toString();
    let bodyFormat = initOptions.popup.emailBody;

    if (!isEmail(emailOrPhoneNumber)) {
        bodyFormat = initOptions.popup.smsBody;
        changeEmailOrPhoneNumber = initOptions.popup.changePhoneNumber;
    }

    body = String.format(bodyFormat, emailOrPhoneNumber);

    popup.showOtp({
        input: {
            title: initOptions.popup.titleInput,
            body: body,
            btnOK: initOptions.popup.btnOK,
            btnCancel: initOptions.popup.btnCancel
        },
        result: {
            title: initOptions.popup.titleResult,
            body: initOptions.popup.resultBody
        },
        emailOrPhoneNumber: emailOrPhoneNumber,
        resendCode: initOptions.popup.resendCode,
        redirectBody: initOptions.popup.redirectBody,
        redirectText: initOptions.popup.redirectText,
        redirectUrl: $(returnUrlSelector).val().toString(),
        regenerateUrl: initOptions.popup.regenerateUrl,
        verifyUrl: initOptions.popup.verifyUrl,
        antiForgeryToken: $(antiForgeryTokenSelector).val().toString(),
        changeEmailOrPhoneNumber: changeEmailOrPhoneNumber,
        enableChangeEmailOrPhoneNumber: true
    });
}

function formBeforeSubmit(_jqXHR: JQuery.jqXHR, _settings: JQuery.Ajax.AjaxSettingsBase<any>, _form: JQuery<HTMLElement>) {
    if (!loader.isShow())
        loader.show(true);
}

function formCallback(response: FormResult, _textStatus: JQuery.Ajax.SuccessTextStatus, _jqXHR: JQuery.jqXHR) {
    loader.hide();

    if (response.isSucceed) {
        showPopup();
        logger.warn('The OTP is:', response.object);
    }
}

export default {
    init: init,
    formBeforeSubmit: formBeforeSubmit,
    formCallback: formCallback
};
